@font-face {
  font-family: "social";
  src: url("../font/social.eot?689717");
  src: url("../font/social.eot?689717#iefix") format("embedded-opentype"),
    url("../font/social.woff?689717") format("woff"),
    url("../font/social.ttf?689717") format("truetype"),
    url("../font/social.svg?689717#social") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'social';
    src: url('../font/social.svg?689717#social') format('svg');
  }
}
*/

.icon {
  border-bottom: 0;
  background: none;
  font-size: 120%;
}

.icon span {
  text-indent: -9999px;
  display: block;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "social";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
}

.icon-facebook:before {
  content: "\e806";
} /* '' */
.icon-twitter:before {
  content: "\e807";
} /* '' */

footer {
  @include grid-row;
  margin-top: baseline(3);
}
.social {
  @include grid-column(12);
  .icon-facebook {
    margin-left: -0.5em;
  }

  ul {
    list-style: none;
    width: 100%;
    padding: 0;
  }

  li {
    float: left;
    margin-right: 1em;
  }
}
