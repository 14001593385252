body {
  counter-reset: h2counter;
}

.text,
.page {
  .content {
    h1 {
      counter-reset: h2counter;
    }

    h2 {
      counter-reset: h3counter;
      counter-increment: h2counter;
    }

    // h2:before {
    //   content: counter(h2counter) '.\0000a0';
    // }

    h3 {
      counter-increment: h3counter;
    }

    // h3:before {
    //   content: counter(h2counter) '.' counter(h3counter) '.\0000a0';
    // }

    img {
      margin: baseline() 0;
    }

    ul,
    ol {
      padding-left: $indent;
    }
  }
}

.content-list {
  @include grid-row();
}

.content-list__intro {
  @include grid-column(12);
  margin-bottom: $baseline * 2;
}

article.text {
  @include grid-column(12);
  & + & {
    &:before {
      content: '* * *';
      text-align: center;
      display: block;
    }
  }
}

.text-header {
  margin-bottom: baseline();
}

.text-page {
  .text-content {
    margin-top: baseline();
  }
}

.footnotes {
  margin-top: baseline(2);
  hr {
    display: none;
  }

  ol,
  li,
  p {
    @include footnote;
  }

  :target p {
    animation-name: flash;
    animation-duration: 1s;
  }
}

@keyframes flash {
  0%,
  100% {
    background: rgba(255, 255, 255, 0.3);
  }

  30% {
    background: rgba(255, 255, 0, 0.3);
  }
}
