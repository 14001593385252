//
// FOUNDATION SETTINGS
//

// Uncomment to use rem-calc() in your settings
// @import "foundation/functions";

// $experimental: true;

// The default font-size is set to 100% of the browser style sheet (usually 16px)
// for compatibility with brower-based text zoom or user-set defaults.

// Since the typical default browser font-size is 16px, that makes the calculation for grid size.
// If you want your base font-size to be different and not have it affect the grid breakpoints,
// set $rem-base to $base-font-size and make sure $base-font-size is a px value.
$base-font-size: 100%;

// The $base-line-height is 100% while $base-font-size is 150%
$base-line-height: 150%; // 24px

$baseline: 28px;

$small-font-size: rem-calc(14);

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// We use this to control whether or not CSS classes come through in the gem files.
$include-html-classes: true;
$include-print-styles: true;
$include-html-global-classes: $include-html-classes;

// Grid

// $include-html-grid-classes: $include-html-classes;

$row-width: rem-calc(900);
$column-gutter: rem-calc(60);
$total-columns: 12;

// We use these to control various global styles
$body-bg: #fff;
$body-font-color: black;
$body-font-family: 'Gill Sans', Arial, sans-serif;
$body-font-weight: normal;
$body-font-style: normal;

// We use this to control font-smoothing
$font-smoothing: antialiased;

// We use these to control text direction settings
// $text-direction: ltr;
// $opposite-direction: right;
// $default-float: left;

// We use these as default colors throughout
$primary-color: $body-font-color;
$secondary-color: $body-font-color;
$alert-color: $body-font-color;
$success-color: $body-font-color;
$warning-color: $body-font-color;
$info-color: $body-font-color;

// We use these to make sure border radius matches unless we want it different.
$global-radius: 0;
$global-rounded: 0;

// We use these to control inset shadow shiny edges and depressions.
// $shiny-edge-size: 0 1px 0;
// $shiny-edge-color: rgba(#fff, .5);
// $shiny-edge-active-color: rgba(#000, .2);

// Media Query Ranges
$small-range: (0em, 45em);
$medium-range: (45.063em, 64em);
$large-range: (64.063em, 90em);
$xlarge-range: (90.063em, 120em);
$xxlarge-range: (120.063em);

$screen: 'only screen';

$landscape: '#{$screen} and (orientation: landscape)';
$portrait: '#{$screen} and (orientation: portrait)';

$small-up: $screen;
$small-only: '#{$screen} and (max-width: #{upper-bound($small-range)})';

$medium-up: '#{$screen} and (min-width:#{lower-bound($medium-range)})';
$medium-only: '#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})';

$large-up: '#{$screen} and (min-width:#{lower-bound($large-range)})';
$large-only: '#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})';

$xlarge-up: '#{$screen} and (min-width:#{lower-bound($xlarge-range)})';
$xlarge-only: '#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})';

$xxlarge-up: '#{$screen} and (min-width:#{lower-bound($xxlarge-range)})';
$xxlarge-only: '#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})';

// Legacy
// $small: $medium-up;
// $medium: $medium-up;
// $large: $large-up;

//We use this as cursors values for enabling the option of having custom cursors in the whole site's stylesheet
// $cursor-crosshair-value: crosshair;
// $cursor-default-value: default;
// $cursor-pointer-value: pointer;
// $cursor-help-value: help;
// $cursor-text-value: text;

// Accordion

// $include-html-accordion-classes: $include-html-classes;

// $accordion-navigation-padding: rem-calc(16);
// $accordion-navigation-bg-color: #efefef  ;
// $accordion-navigation-hover-bg-color: darken($accordion-navigation-bg-color, 5%);
// $accordion-navigation-active-bg-color: darken($accordion-navigation-bg-color, 3%);
// $accordion-navigation-font-color: #222;
// $accordion-navigation-font-size: rem-calc(16);
$accordion-navigation-font-family: $body-font-family;

// $accordion-content-padding: $column-gutter/2;
// $accordion-content-active-bg-color: #fff;

// Alert Boxes

// $include-html-alert-classes: $include-html-classes;

// We use this to control alert padding.
// $alert-padding-top: rem-calc(14);
// $alert-padding-default-float: $alert-padding-top;
// $alert-padding-opposite-direction: $alert-padding-top + rem-calc(10);
// $alert-padding-bottom: $alert-padding-top;

// We use these to control text style.
// $alert-font-weight: normal;
// $alert-font-size: rem-calc(13);
// $alert-font-color: #fff;
// $alert-font-color-alt: darken($secondary-color, 60%);

// We use this for close hover effect.
// $alert-function-factor: 5%;

// We use these to control border styles.
// $alert-border-style: solid;
// $alert-border-width: 1px;
// $alert-border-color: darken($primary-color, $alert-function-factor);
// $alert-bottom-margin: rem-calc(20);

// We use these to style the close buttons
// $alert-close-color: #333;
// $alert-close-top: 50%;
// $alert-close-position: rem-calc(5);
// $alert-close-font-size: rem-calc(22);
// $alert-close-opacity: 0.3;
// $alert-close-opacity-hover: 0.5;
// $alert-close-padding: 9px 6px 4px;

// We use this to control border radius
// $alert-radius: $global-radius;

// Block Grid

// $include-html-grid-classes: $include-html-classes;

// We use this to control the maximum number of block grid elements per row
// $block-grid-elements: 12;
// $block-grid-default-spacing: rem-calc(20);

// Enables media queries for block-grid classes. Set to false if writing semantic HTML.
// $block-grid-media-queries: true;

// Breadcrumbs

// $include-html-nav-classes: $include-html-classes;

// We use this to set the background color for the breadcrumb container.
// $crumb-bg: lighten($secondary-color, 5%);

// We use these to set the padding around the breadcrumbs.
// $crumb-padding: rem-calc(9 14 9);
// $crumb-side-padding: rem-calc(12);

// We use these to control border styles.
// $crumb-function-factor: 10%;
// $crumb-border-size: 1px;
// $crumb-border-style: solid;
// $crumb-border-color: darken($crumb-bg, $crumb-function-factor);
// $crumb-radius: $global-radius;

// We use these to set various text styles for breadcrumbs.
// $crumb-font-size: rem-calc(11);
// $crumb-font-color: $primary-color;
// $crumb-font-color-current: #333;
// $crumb-font-color-unavailable: #999;
// $crumb-font-transform: uppercase;
// $crumb-link-decor: underline;

// We use these to control the slash between breadcrumbs
// $crumb-slash-color: #aaa;
// $crumb-slash: "/";

// Button Groups

// $include-html-button-classes: $include-html-classes;

// Sets the margin for the right side by default, and the left margin if right-to-left direction is used
// $button-bar-margin-opposite: rem-calc(10);
// $button-group-border-width: 1px;

// Clearing

// $include-html-clearing-classes: $include-html-classes;

// We use these to set the background colors for parts of Clearing.
// $clearing-bg: #333;
// $clearing-caption-bg: $clearing-bg;
// $clearing-carousel-bg: rgba (51,51,51,0.8);
// $clearing-img-bg: $clearing-bg;

// We use these to style the close button
// $clearing-close-color: #ccc;
// $clearing-close-size: 30px;

// We use these to style the arrows
// $clearing-arrow-size: 12px;
// $clearing-arrow-color: $clearing-close-color;

// We use these to style captions
// $clearing-caption-font-color: #ccc;
// $clearing-caption-font-size: 0.875em;
// $clearing-caption-padding: 10px 30px 20px;

// We use these to make the image and carousel height and style
// $clearing-active-img-height: 85%;
// $clearing-carousel-height: 120px;
// $clearing-carousel-thumb-width: 120px;
// $clearing-carousel-thumb-active-border: 1px solid rgb(255,255,255);

// Dropdown

// $include-html-button-classes: $include-html-classes;

// We use these to controls height and width styles.
// $f-dropdown-max-width: 200px;
// $f-dropdown-height: auto;
// $f-dropdown-max-height: none;
// $f-dropdown-margin-top: 2px;

// We use this to control the background color
// $f-dropdown-bg: #fff;

// We use this to set the border styles for dropdowns.
// $f-dropdown-border-style: solid;
// $f-dropdown-border-width: 1px;
// $f-dropdown-border-color: darken(#fff, 20%);

// We use these to style the triangle pip.
// $f-dropdown-triangle-size: 6px;
// $f-dropdown-triangle-color: #fff;
// $f-dropdown-triangle-side-offset: 10px;

// We use these to control styles for the list elements.
// $f-dropdown-list-style: none;
// $f-dropdown-font-color: #555;
// $f-dropdown-font-size: rem-calc(14);
// $f-dropdown-list-padding: rem-calc(5, 10);
// $f-dropdown-line-height: rem-calc(18);
// $f-dropdown-list-hover-bg: #eeeeee  ;
// $dropdown-mobile-default-float: 0;

// We use this to control the styles for when the dropdown has custom content.
// $f-dropdown-content-padding: rem-calc(20);

// Dropdown Buttons

// $include-html-button-classes: $include-html-classes;

// We use these to set the color of the pip in dropdown buttons
// $dropdown-button-pip-color: #fff;
// $dropdown-button-pip-color-alt: #333;

// $button-pip-tny: rem-calc(6);
// $button-pip-sml: rem-calc(7);
// $button-pip-med: rem-calc(9);
// $button-pip-lrg: rem-calc(11);

// We use these to style tiny dropdown buttons
// $dropdown-button-padding-tny: $button-pip-tny * 7;
// $dropdown-button-pip-size-tny: $button-pip-tny;
// $dropdown-button-pip-opposite-tny: $button-pip-tny * 3;
// $dropdown-button-pip-top-tny: -$button-pip-tny / 2 + rem-calc(1);

// We use these to style small dropdown buttons
// $dropdown-button-padding-sml: $button-pip-sml * 7;
// $dropdown-button-pip-size-sml: $button-pip-sml;
// $dropdown-button-pip-opposite-sml: $button-pip-sml * 3;
// $dropdown-button-pip-top-sml: -$button-pip-sml / 2 + rem-calc(1);

// We use these to style medium dropdown buttons
// $dropdown-button-padding-med: $button-pip-med * 6 + rem-calc(3);
// $dropdown-button-pip-size-med: $button-pip-med - rem-calc(3);
// $dropdown-button-pip-opposite-med: $button-pip-med * 2.5;
// $dropdown-button-pip-top-med: -$button-pip-med / 2 + rem-calc(2);

// We use these to style large dropdown buttons
// $dropdown-button-padding-lrg: $button-pip-lrg * 5 + rem-calc(3);
// $dropdown-button-pip-size-lrg: $button-pip-lrg - rem-calc(6);
// $dropdown-button-pip-opposite-lrg: $button-pip-lrg * 2.5;
// $dropdown-button-pip-top-lrg: -$button-pip-lrg / 2 + rem-calc(3);

// Flex Video

// $include-html-media-classes: $include-html-classes;

// We use these to control video container padding and margins
// $flex-video-padding-top: rem-calc(25);
// $flex-video-padding-bottom: 67.5%;
// $flex-video-margin-bottom: rem-calc(16);

// We use this to control widescreen bottom padding
// $flex-video-widescreen-padding-bottom: 57.25%;

// Forms

// $include-html-form-classes: $include-html-classes;

// We use this to set the base for lots of form spacing and positioning styles
// $form-spacing: rem-calc(16);

// We use these to style the labels in different ways
// $form-label-pointer: pointer;
// $form-label-font-size: rem-calc(14);
// $form-label-font-weight: normal;
// $form-label-font-color: lighten(#000, 30%);
// $form-label-bottom-margin: rem-calc(8);
// $input-font-family: inherit;
// $input-font-color: rgba(0,0,0,0.75);
// $input-font-size: rem-calc(14);
// $input-bg-color: #fff;
// $input-focus-bg-color: darken(#fff, 2%);
// $input-border-color: darken(#fff, 20%);
// $input-focus-border-color: darken(#fff, 40%);
// $input-border-style: solid;
// $input-border-width: 1px;
// $input-disabled-bg: #ddd;
// $input-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
// $input-include-glowing-effect: true;

// We use these to style the fieldset border and spacing.
// $fieldset-border-style: solid;
// $fieldset-border-width: 1px;
// $fieldset-border-color: #ddd;
// $fieldset-padding: rem-calc(20);
// $fieldset-margin: rem-calc(18 0);

// We use these to style the legends when you use them
// $legend-bg: #fff;
// $legend-font-weight: bold;
// $legend-padding: rem-calc(0 3);

// We use these to style the prefix and postfix input elements
// $input-prefix-bg: darken(#fff, 5%);
// $input-prefix-border-color: darken(#fff, 20%);
// $input-prefix-border-size: 1px;
// $input-prefix-border-type: solid;
// $input-prefix-overflow: hidden;
// $input-prefix-font-color: #333;
// $input-prefix-font-color-alt: #fff;

// We use these to style the error states for inputs and labels
// $input-error-message-padding: rem-calc(6 9 9);
// $input-error-message-top: -1px;
// $input-error-message-font-size: rem-calc(12);
// $input-error-message-font-weight: normal;
// $input-error-message-font-style: italic;
// $input-error-message-font-color: #fff;
// $input-error-message-font-color-alt: #333;

// We use this to style the glowing effect of inputs when focused
// $glowing-effect-fade-time: 0.45s;
// $glowing-effect-color: $input-focus-border-color;

// Select variables
// $select-bg-color: #fafafa  ;

// Inline Lists

// $include-html-inline-list-classes: $include-html-classes;

// We use this to control the margins and padding of the inline list.
// $inline-list-top-margin: 0;
// $inline-list-opposite-margin: 0;
// $inline-list-bottom-margin: rem-calc(17);
// $inline-list-default-float-margin: rem-calc(-22);

// $inline-list-padding: 0;

// We use this to control the overflow of the inline list.
// $inline-list-overflow: hidden;

// We use this to control the list items
// $inline-list-display: block;

// We use this to control any elments within list items
// $inline-list-children-display: block;

// Joyride

// $include-html-joyride-classes: $include-html-classes;

// Controlling default Joyride styles
// $joyride-tip-bg: #333;
// $joyride-tip-default-width: 300px;
// $joyride-tip-padding: rem-calc(18 20 24);
// $joyride-tip-border: solid 1px #555;
// $joyride-tip-radius: 4px;
// $joyride-tip-position-offset: 22px;

// Here, we're setting the tip dont styles
// $joyride-tip-font-color: #fff;
// $joyride-tip-font-size: rem-calc(14);
// $joyride-tip-header-weight: bold;

// This changes the nub size
// $joyride-tip-nub-size: 10px;

// This adjusts the styles for the timer when its enabled
// $joyride-tip-timer-width: 50px;
// $joyride-tip-timer-height: 3px;
// $joyride-tip-timer-color: #666;

// This changes up the styles for the close button
// $joyride-tip-close-color: #777;
// $joyride-tip-close-size: 24px;
// $joyride-tip-close-weight: normal;

// When Joyride is filling the screen, we use this style for the bg
// $joyride-screenfill: rgba(0,0,0,0.5);

// Keystrokes

// $include-html-type-classes: $include-html-classes;

// We use these to control text styles.
// $keystroke-font: "Consolas", "Menlo", "Courier", monospace;
// $keystroke-font-size: rem-calc(14);
// $keystroke-font-color: #222;
// $keystroke-font-color-alt: #fff;
// $keystroke-function-factor: 7%;

// We use this to control keystroke padding.
// $keystroke-padding: rem-calc(2 4 0);

// We use these to control background and border styles.
// $keystroke-bg: darken(#fff, $keystroke-function-factor);
// $keystroke-border-style: solid;
// $keystroke-border-width: 1px;
// $keystroke-border-color: darken($keystroke-bg, $keystroke-function-factor);
// $keystroke-radius: $global-radius;

// Labels

// $include-html-label-classes: $include-html-classes;

// We use these to style the labels
// $label-padding: rem-calc(4 8 6);
// $label-radius: $global-radius;

// We use these to style the label text
// $label-font-sizing: rem-calc(11);
// $label-font-weight: normal;
// $label-font-color: #333;
// $label-font-color-alt: #fff;
$label-font-family: $body-font-family;

// Magellan

// $include-html-magellan-classes: $include-html-classes;

// $magellan-bg: #fff;
// $magellan-padding: 10px;

// Off-canvas

// $tabbar-bg: #333;
// $tabbar-height: rem-calc(45);
// $tabbar-line-height: $tabbar-height;
// $tabbar-color: #FFF;
// $tabbar-middle-padding: 0 rem-calc(10);

// Off Canvas Divider Styles
// $tabbar-right-section-border: solid 1px lighten($tabbar-bg, 10%);
// $tabbar-left-section-border: solid 1px darken($tabbar-bg, 10%);

// Off Canvas Tab Bar Headers
// $tabbar-header-color: #FFF;
// $tabbar-header-weight: bold;
// $tabbar-header-line-height: $tabbar-height;
// $tabbar-header-margin: 0;

// Off Canvas Menu Variables
// $off-canvas-width: 250px;
// $off-canvas-bg: #333;

// Off Canvas Menu List Variables
// $off-canvas-label-padding: 0.3rem rem-calc(15);
// $off-canvas-label-color: #999;
// $off-canvas-label-text-transform: uppercase;
// $off-canvas-label-font-weight: bold;
// $off-canvas-label-bg: #444;
// $off-canvas-label-border-top: 1px solid lighten(#444, 10%);
// $off-canvas-label-border-bottom: none;
// $off-canvas-label-margin:0;
// $off-canvas-link-padding: rem-calc(10, 15);
// $off-canvas-link-color: rgba(#FFF, 0.7);
// $off-canvas-link-border-bottom: 1px solid darken($off-canvas-bg, 5%);

// Off Canvas Menu Icon Variables
// $tabbar-menu-icon-color: #FFF;
// $tabbar-menu-icon-hover: darken($tabbar-menu-icon-color, 30%);

// $tabbar-menu-icon-text-indent: rem-calc(35);
// $tabbar-menu-icon-width: $tabbar-height;
// $tabbar-menu-icon-height: $tabbar-height;
// $tabbar-menu-icon-line-height: rem-calc(33);
// $tabbar-menu-icon-padding: 0;

// $tabbar-hamburger-icon-width: rem-calc(16);
// $tabbar-hamburger-icon-left: rem-calc(13);
// $tabbar-hamburger-icon-top: rem-calc(5);

// Off Canvas Back-Link Overlay
// $off-canvas-overlay-transition: background 300ms ease;
// $off-canvas-overlay-cursor: pointer;
// $off-canvas-overlay-box-shadow: -4px 0 4px rgba(#000, 0.5), 4px 0 4px rgba(#000, 0.5);
// $off-canvas-overlay-background: rgba(#FFF, 0.2);
// $off-canvas-overlay-background-hover: rgba(#FFF, 0.05);

// Transition Variabls
// $menu-slide: "transform 500ms ease";

// Orbit

// $include-html-orbit-classes: $include-html-classes;

// We use these to control the caption styles
// $orbit-container-bg: none;
// $orbit-caption-bg: rgba(51,51,51, 0.8);
// $orbit-caption-font-color: #fff;
// $orbit-caption-font-size: rem-calc(14);
// $orbit-caption-position: "bottom"; // Supported values: "bottom", "under"
// $orbit-caption-padding: rem-calc(10,14);
// $orbit-caption-height: auto;

// We use these to control the left/right nav styles
// $orbit-nav-bg: none;
// $orbit-nav-bg-hover: rgba(0,0,0,0.3);
// $orbit-nav-arrow-color: #fff;
// $orbit-nav-arrow-color-hover: #fff;

// We use these to control the timer styles
// $orbit-timer-bg: rgba(255,255,255,0.3);
// $orbit-timer-show-progress-bar: true;

// We use these to control the bullet nav styles
// $orbit-bullet-nav-color: #ccc;
// $orbit-bullet-nav-color-active: #999;
// $orbit-bullet-radius: rem-calc(9);

// We use these to controls the style of slide numbers
// $orbit-slide-number-bg: rgba(0,0,0,0);
// $orbit-slide-number-font-color: #fff;
// $orbit-slide-number-padding: rem-calc(5);

// Graceful Loading Wrapper and preloader
// $wrapper-class: "slideshow-wrapper";
// $preloader-class: "preloader";

// Pagination

// $include-html-nav-classes: $include-html-classes;

// We use these to control the pagination container
// $pagination-height: rem-calc(24);
// $pagination-margin: rem-calc(-5);

// We use these to set the list-item properties
// $pagination-li-float: $default-float;
// $pagination-li-height: rem-calc(24);
// $pagination-li-font-color: #222;
// $pagination-li-font-size: rem-calc(14);
// $pagination-li-margin: rem-calc(5);

// We use these for the pagination anchor links
// $pagination-link-pad: rem-calc(1 10 1);
// $pagination-link-font-color: #999;
// $pagination-link-active-bg: darken(#fff, 10%);

// We use these for disabled anchor links
// $pagination-link-unavailable-cursor: default;
// $pagination-link-unavailable-font-color: #999;
// $pagination-link-unavailable-bg-active: transparent;

// We use these for currently selected anchor links
// $pagination-link-current-background: $primary-color;
// $pagination-link-current-font-color: #fff;
// $pagination-link-current-font-weight: bold;
// $pagination-link-current-cursor: default;
// $pagination-link-current-active-bg: $primary-color;

// Panels

// $include-html-panel-classes: $include-html-classes;

// We use these to control the background and border styles
// $panel-bg: darken(#fff, 5%);
// $panel-border-style: solid;
// $panel-border-size: 1px;

// We use this % to control how much we darken things on hover
// $panel-function-factor: 10%;
// $panel-border-color: darken($panel-bg, $panel-function-factor);

// We use these to set default inner padding and bottom margin
// $panel-margin-bottom: rem-calc(20);
// $panel-padding: rem-calc(20);

// We use these to set default font colors
// $panel-font-color: #333;
// $panel-font-color-alt: #fff;

// $panel-header-adjust: true;
// $callout-panel-link-color: $primary-color;

// Pricing Tables

// $include-html-pricing-classes: $include-html-classes;

// We use this to control the border color
// $price-table-border: solid 1px #ddd;

// We use this to control the bottom margin of the pricing table
// $price-table-margin-bottom: rem-calc(20);

// We use these to control the title styles
// $price-title-bg: #333;
// $price-title-padding: rem-calc(15 20);
// $price-title-align: center;
// $price-title-color: #eee;
// $price-title-weight: normal;
// $price-title-size: rem-calc(16);
$price-title-font-family: $body-font-family;

// We use these to control the price styles
// $price-money-bg: #f6f6f6  ;
// $price-money-padding: rem-calc(15 20);
// $price-money-align: center;
// $price-money-color: #333;
// $price-money-weight: normal;
// $price-money-size: rem-calc(32);
$price-money-font-family: $body-font-family;

// We use these to control the description styles
// $price-bg: #fff;
// $price-desc-color: #777;
// $price-desc-padding: rem-calc(15);
// $price-desc-align: center;
// $price-desc-font-size: rem-calc(12);
// $price-desc-weight: normal;
// $price-desc-line-height: 1.4;
// $price-desc-bottom-border: dotted 1px #ddd;

// We use these to control the list item styles
// $price-item-color: #333;
// $price-item-padding: rem-calc(15);
// $price-item-align: center;
// $price-item-font-size: rem-calc(14);
// $price-item-weight: normal;
// $price-item-bottom-border: dotted 1px #ddd;

// We use these to control the CTA area styles
// $price-cta-bg: #fff;
// $price-cta-align: center;
// $price-cta-padding: rem-calc(20 20 0);

// Progress Meters

// $include-html-media-classes: $include-html-classes;

// We use this to se the prog bar height
// $progress-bar-height: rem-calc(25);
// $progress-bar-color: #f6f6f6  ;

// We use these to control the border styles
// $progress-bar-border-color: darken(#fff, 20%);
// $progress-bar-border-size: 1px;
// $progress-bar-border-style: solid;
// $progress-bar-border-radius: $global-radius;

// We use these to control the margin & padding
// $progress-bar-pad: rem-calc(2);
// $progress-bar-margin-bottom: rem-calc(10);

// We use these to set the meter colors
// $progress-meter-color: $primary-color;
// $progress-meter-secondary-color: $secondary-color;
// $progress-meter-success-color: $success-color;
// $progress-meter-alert-color: $alert-color;

// Reveal

// $include-html-reveal-classes: $include-html-classes;

// We use these to control the style of the reveal overlay.
// $reveal-overlay-bg: rgba(#000, .45);
// $reveal-overlay-bg-old: #000;

// We use these to control the style of the modal itself.
// $reveal-modal-bg: #fff;
// $reveal-position-top: 50px;
// $reveal-default-width: 80%;
// $reveal-modal-padding: rem-calc(20);
// $reveal-box-shadow: 0 0 10px rgba(#000,.4);

// We use these to style the reveal close button
// $reveal-close-font-size: rem-calc(22);
// $reveal-close-top: rem-calc(8);
// $reveal-close-side: rem-calc(11);
// $reveal-close-color: #aaa;
// $reveal-close-weight: bold;

// We use these to control the modal border
// $reveal-border-style: solid;
// $reveal-border-width: 1px;
// $reveal-border-color: #666;

// $reveal-modal-class: "reveal-modal";
// $close-reveal-modal-class: "close-reveal-modal";

// Side Nav

// $include-html-nav-classes: $include-html-classes;

// We use this to control padding.
// $side-nav-padding: rem-calc(14 0);

// We use these to control list styles.
// $side-nav-list-type: none;
// $side-nav-list-position: inside;
// $side-nav-list-margin: rem-calc(0 0 7 0);

// We use these to control link styles.
// $side-nav-link-color: $primary-color;
// $side-nav-link-color-active: lighten(#000, 30%);
// $side-nav-font-size: rem-calc(14);
// $side-nav-font-weight: normal;
$side-nav-font-family: $body-font-family;
$side-nav-active-font-family: $side-nav-font-family;

// We use these to control border styles
// $side-nav-divider-size: 1px;
// $side-nav-divider-style: solid;
// $side-nav-divider-color: darken(#fff, 10%);

// Split Buttons

// $include-html-button-classes: $include-html-classes;

// We use these to control different shared styles for Split Buttons
// $split-button-function-factor: 10%;
// $split-button-pip-color: #fff;
// $split-button-pip-color-alt: #333;
// $split-button-active-bg-tint: rgba(0,0,0,0.1);

// We use these to control tiny split buttons
// $split-button-padding-tny: $button-pip-tny * 10;
// $split-button-span-width-tny: $button-pip-tny * 6;
// $split-button-pip-size-tny: $button-pip-tny;
// $split-button-pip-top-tny: $button-pip-tny * 2;
// $split-button-pip-default-float-tny: rem-calc(-6);

// We use these to control small split buttons
// $split-button-padding-sml: $button-pip-sml * 10;
// $split-button-span-width-sml: $button-pip-sml * 6;
// $split-button-pip-size-sml: $button-pip-sml;
// $split-button-pip-top-sml: $button-pip-sml * 1.5;
// $split-button-pip-default-float-sml: rem-calc(-6);

// We use these to control medium split buttons
// $split-button-padding-med: $button-pip-med * 9;
// $split-button-span-width-med: $button-pip-med * 5.5;
// $split-button-pip-size-med: $button-pip-med - rem-calc(3);
// $split-button-pip-top-med: $button-pip-med * 1.5;
// $split-button-pip-default-float-med: rem-calc(-6);

// We use these to control large split buttons
// $split-button-padding-lrg: $button-pip-lrg * 8;
// $split-button-span-width-lrg: $button-pip-lrg * 5;
// $split-button-pip-size-lrg: $button-pip-lrg - rem-calc(6);
// $split-button-pip-top-lrg: $button-pip-lrg + rem-calc(5);
// $split-button-pip-default-float-lrg: rem-calc(-6);

// Sub Nav

// $include-html-nav-classes: $include-html-classes;

// We use these to control margin and padding
// $sub-nav-list-margin: rem-calc(-4 0 18);
// $sub-nav-list-padding-top: rem-calc(4);

// We use this to control the definition
$sub-nav-font-family: $body-font-family;
// $sub-nav-font-size: rem-calc(14);
// $sub-nav-font-color: #999;
// $sub-nav-font-weight: normal;
// $sub-nav-text-decoration: none;
// $sub-nav-border-radius: 3px;
// $sub-nav-font-color-hover: darken($sub-nav-font-color, 15%);

// We use these to control the active item styles

// $sub-nav-active-font-weight: normal;
// $sub-nav-active-bg: $primary-color;
// $sub-nav-active-bg-hover: darken($sub-nav-active-bg, 5%);
// $sub-nav-active-color: #fff;
// $sub-nav-active-padding: rem-calc(3 16);
// $sub-nav-active-cursor: default;

// $sub-nav-item-divider: "";
// $sub-nav-item-divider-margin: rem-calc(12);

// Tables

// $include-html-table-classes: $include-html-classes;

// These control the background color for the table and even rows
// $table-bg: #fff;
// $table-even-row-bg: #f9f9f9  ;

// These control the table cell border style
// $table-border-style: solid;
// $table-border-size: 1px;
// $table-border-color: #ddd;

// These control the table head styles
// $table-head-bg: #f5f5f5  ;
// $table-head-font-size: rem-calc(14);
// $table-head-font-color: #222;
// $table-head-font-weight: bold;
// $table-head-padding: rem-calc(8 10 10);

// These control the row padding and font styles
// $table-row-padding: rem-calc(9 10);
// $table-row-font-size: rem-calc(14);
// $table-row-font-color: #222;
// $table-line-height: rem-calc(18);

// These are for controlling the display and margin of tables
// $table-display: table-cell;
// $table-margin-bottom: rem-calc(20);

//
// TABS
//

// $include-html-tabs-classes: $include-html-classes;

// $tabs-navigation-padding: rem-calc(16);
// $tabs-navigation-bg-color: #efefef  ;
// $tabs-navigation-hover-bg-color: darken($tabs-navigation-bg-color, 5%);
// $tabs-navigation-font-color: #222;
// $tabs-navigation-font-size: rem-calc(16);
$tabs-navigation-font-family: $body-font-family;

// $tabs-content-margin-bottom: rem-calc(24);
// $tabs-content-padding: $column-gutter/2;

// $tabs-vertical-navigation-margin-bottom: 1.25rem;

//
// THUMBNAILS
//

// $include-html-media-classes: $include-html-classes;

// We use these to control border styles
// $thumb-border-style: solid;
// $thumb-border-width: 4px;
// $thumb-border-color: #fff;
// $thumb-box-shadow: 0 0 0 1px rgba(#000,.2);
// $thumb-box-shadow-hover: 0 0 6px 1px rgba($primary-color,0.5);

// Radius and transition speed for thumbs
// $thumb-radius: $global-radius;
// $thumb-transition-speed: 200ms;

//
// TOOLTIPS
//

// $include-html-tooltip-classes: $include-html-classes;

// $has-tip-border-bottom: dotted 1px #ccc;
// $has-tip-font-weight: bold;
// $has-tip-font-color: #333;
// $has-tip-border-bottom-hover: dotted 1px darken($primary-color, 20%);
// $has-tip-font-color-hover: $primary-color;
// $has-tip-cursor-type: help;

// $tooltip-padding: rem-calc(12);
// $tooltip-bg: #333;
// $tooltip-font-size: rem-calc(14);
// $tooltip-font-weight: normal;
// $tooltip-font-color: #fff;
// $tooltip-line-height: 1.3;
// $tooltip-close-font-size: rem-calc(10);
// $tooltip-close-font-weight: normal;
// $tooltip-close-font-color: #777;
// $tooltip-font-size-sml: rem-calc(14);
// $tooltip-radius: $global-radius;
// $tooltip-pip-size: 5px;

//
// TOP BAR
//

// $include-html-top-bar-classes: $include-html-classes;

// Background color for the top bar
// $topbar-bg-color: #333;
// $topbar-bg: $topbar-bg-color;

// Height and margin
// $topbar-height: 45px;
// $topbar-margin-bottom: 0;

// Control Input height for top bar

// Controlling the styles for the title in the top bar
// $topbar-title-weight: normal;
// $topbar-title-font-size: rem-calc(17);

// Style the top bar dropdown elements
// $topbar-dropdown-bg: #333;
// $topbar-dropdown-link-color: #fff;
// $topbar-dropdown-link-bg: #333;
// $topbar-dropdown-toggle-size: 5px;
// $topbar-dropdown-toggle-color: #fff;
// $topbar-dropdown-toggle-alpha: 0.4;

// Set the link colors and styles for top-level nav
// $topbar-link-color: #fff;
// $topbar-link-color-hover: #fff;
// $topbar-link-color-active: #fff;
// $topbar-link-weight: normal;
// $topbar-link-font-size: rem-calc(13);
// $topbar-link-hover-lightness: -10%; // Darken by 10%
// $topbar-link-bg-hover: #272727  ;
// $topbar-link-bg-active: $primary-color;
// $topbar-link-bg-active-hover: darken($primary-color, 5%);
$topbar-link-font-family: $body-font-family;

// $topbar-button-font-size: 0.75rem;

// $topbar-dropdown-label-color: #777;
// $topbar-dropdown-label-text-transform: uppercase;
// $topbar-dropdown-label-font-weight: bold;
// $topbar-dropdown-label-font-size: rem-calc(10);
// $topbar-dropdown-label-bg: #333;

// Top menu icon styles
// $topbar-menu-link-transform: uppercase;
// $topbar-menu-link-font-size: rem-calc(13);
// $topbar-menu-link-weight: bold;
// $topbar-menu-link-color: #fff;
// $topbar-menu-icon-color: #fff;
// $topbar-menu-link-color-toggled: #888;
// $topbar-menu-icon-color-toggled: #888;

// Transitions and breakpoint styles
// $topbar-transition-speed: 300ms;
// Using rem-calc for the below breakpoint causes issues with top bar
// $topbar-breakpoint: #{upper-bound($medium-range)}; // Change to 9999px for always mobile layout
// $topbar-media-query: "only screen and (min-width: #{upper-bound($medium-range)})";

// Divider Styles
// $topbar-divider-border-bottom: solid 1px lighten($topbar-bg-color, 10%);
// $topbar-divider-border-top: solid 1px darken($topbar-bg-color, 10%);

// Sticky Class
// $topbar-sticky-class: ".sticky";
// $topbar-arrows: true; //Set false to remove the triangle icon from the menu item
