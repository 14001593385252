$include-html-type-classes: $include-html-classes;

// We use these to control header font styles
$header-font-family: $body-font-family;
$header-font-weight: normal;
$header-font-style: normal;
$header-font-color: $body-font-color;
$header-line-height: baseline(1);
$header-top-margin: 0;
$header-bottom-margin: 0;
$header-text-rendering: optimizeLegibility;

// We use these to control header font sizes
$h1-font-size: rem-calc(28);
$h2-font-size: rem-calc(28);
$h3-font-size: rem-calc(28);
$h4-font-size: rem-calc(28);
$h5-font-size: rem-calc(28);
$h6-font-size: 1rem;

// These control how subheaders are styled
$subheader-line-height: baseline(1);
$subheader-font-color:$header-font-color;
$subheader-font-weight: normal;
$subheader-top-margin: 0;
$subheader-bottom-margin: 0;

// A general small styling
$small-font-size: rem-calc(14);
$small-font-color: $header-font-color;

// We use these to style paragraphs
$paragraph-font-family: inherit;
$paragraph-font-weight: normal;
$paragraph-font-size: rem-calc(20);
$paragraph-line-height: baseline(1);
$paragraph-margin-bottom: 0;
$paragraph-aside-font-size: rem-calc(20);
$paragraph-aside-line-height: baseline(1);
$paragraph-aside-font-style: normal;
$paragraph-aside-margin-bottom: baseline(1);
$paragraph-text-rendering: optimizeLegibility;

// We use these to style code tags
$code-color: darken($alert-color, 15%);
$code-font-family: Consolas, Liberation Mono, Courier, monospace;
$code-font-weight: bold;

// We use these to style anchors
$anchor-text-decoration: none;
$anchor-font-color: $primary-color;
$anchor-font-color-hover: darken($primary-color, 5%);

// We use these to style the hr element
$hr-border-width: 1px;
$hr-border-style: solid;
$hr-border-color: #000;
$hr-margin: rem-calc(20);

// We use these to style lists
$list-style-position: outside;
$list-side-margin: 0;
$list-nested-margin: rem-calc(20);
$definition-list-header-weight: bold;
$definition-list-header-margin-bottom: baseline(1);
$definition-list-margin-bottom: baseline(1);

// We use these to style blockquotes
$blockquote-font-color: $header-font-color;
$blockquote-padding: baseline() 1em baseline() 2em;
$blockquote-border: none;
$blockquote-cite-font-size: rem-calc(20);
$blockquote-cite-font-color: $header-font-color;
$blockquote-cite-link-color: $blockquote-cite-font-color;

// Acronym styles
$acronym-underline: 1px dotted #000;

// We use these to control padding and margin
$microformat-padding: rem-calc(10 12);
$microformat-margin: rem-calc(0 0 20 0);

// We use these to control the border styles
$microformat-border-width: 1px;
$microformat-border-style: solid;
$microformat-border-color: #000;

// We use these to control full name font styles
$microformat-fullname-font-weight: bold;
$microformat-fullname-font-size: rem-calc(20);

// We use this to control the summary font styles
$microformat-summary-font-weight: bold;

// We use this to control abbr padding
$microformat-abbr-padding: rem-calc(0 1);

// We use this to control abbr font styles
$microformat-abbr-font-weight: bold;
$microformat-abbr-font-decoration: none;