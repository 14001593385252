@import 'settings';

@function baseline($value: 1) {
  @return $value * 1.35rem;
}

// VARS

$indent: 1.6em;

// Underline
$u-weight: 0.1em;
$u-color: red;
$u-offset: 0.05em;

@import 'type-settings';
@import 'includes/grid';
@import 'includes/type';
@import 'modules/social';
@import 'modules/header';
@import 'modules/lists';
@import 'pages/events';
@import 'pages/texts';
@import 'pages/page';
@import 'pages/home';

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 18px;
  letter-spacing: -0.006em;
  word-spacing: -0.05em;
}

body {
  line-height: baseline();
  padding: 0 $indent/4;
  margin: baseline(0.5) 0 0;
  @media #{$medium-up} {
    padding: 0 $indent;
  }
}

// Root Elements
h1,
h2,
h3 {
  margin: 0 0 baseline();
}

h1 {
  font-size: 1.75rem;
  line-height: baseline(1.5);
  font-weight: normal;
}

h2 {
  font-size: 1.5rem;
  line-height: baseline(1.5);
  font-weight: normal;
}

h3 {
  font-size: 1rem; // 1.375rem;
  font-weight: normal;
  line-height: baseline(1.5);
}

p,
ul,
ol,
table {
  margin-block: baseline(1);
  margin-inline: 0;
  padding: 0;

  & + h1,
  & + h2,
  & + h3 {
    margin-top: baseline(2);
  }
}

p {
  // max-width: 36em;
  margin: 0;
}

p + p {
  margin-top: baseline(1);
}

a {
  color: black;
  text-decoration: none;
  @include underline;
}

.pdf-link {
  font-style: italic;
}

sup {
  line-height: 0;
  padding-left: 0.2em;
  a {
    background-image: none;
  }
}

img {
  max-width: 100%;
}

sup {
  font-size: 0.6em;
}

blockquote {
  margin: baseline() auto;
  padding: 0 baseline();
  font-style: italic;
}

blockquote cite {
  display: block;
  text-indent: -1.2em;
  margin-left: 1em;
  font-style: normal;
  &::before {
    content: '– ';
  }
}

.org-right {
  text-align: right;
}

.org-left {
  text-align: left;
}

.main {
  @include grid-row();
}

.read-more {
  text-indent: 0;
}

.mailing-form {
  @include grid-column(12);
  padding-bottom: baseline();
}
