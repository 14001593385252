article.event {
  margin-bottom: baseline(2);
  @media #{$medium-up} {
    margin-bottom: baseline();
  }
}

.location,
.date {
  text-indent: 0;
}

.location {
  span {
    display: block;
  }
}

.event,
.text {
  .content,
  .event-series-description {
    margin-top: baseline();

    @media #{$medium-up} {
      margin-top: 0;
    }
  }
}

.short-event-list {
  margin-top: baseline(2);
}

// General rules for events
.event {
  .event-header {
    margin-bottom: baseline();
  }

  .project-title {
    margin-top: 0;
  }

  .info {
    margin-bottom: baseline();
    padding-left: baseline(0.5);
    border-left: 3px solid red;

    p {
      font-size: 0.8rem; //$small-font-size;
      line-height: 1.4;
    }
  }

  .content {
    clear: both;
  }
}

// List of events
.list {
  @include grid-row;
}

// Single event (expanded and short), inside a list
.list .event {
  @include grid-column(12, $collapse: true);
}

.list > article + article {
  // border-top: 1px solid;
  &::before {
    content: '* * *';
    text-align: center;
    width: 100%;
    display: block;
    padding-bottom: baseline();
  }
}

// Event series
.event-series {
  @include grid-column(12, $collapse: false);
  @media #{$medium-up} {
    @include grid-column(12, $collapse: true);
  }

  > .event-header {
    @include grid-column(12, $collapse: true);
    @media #{$medium-up} {
      @include grid-column(12, $collapse: false);
    }
  }

  .event-series-description {
    @include grid-column(12, $collapse: true);
    @media #{$medium-up} {
      @include grid-column(12, $collapse: false);
    }
    margin-bottom: baseline();
  }
}

.event-series-list {
  margin-top: baseline();
  counter-reset: section;
  @include grid-row();

  article.event {
    @include grid-column(12, $collapse: true);
    margin-bottom: 0;
    margin-top: 0;

    h1:before {
      width: 1em;
      margin-left: -1.1em;
      display: inline-block;
      counter-increment: section;
      content: counter(section);
    }

    .content {
      margin-bottom: baseline();
    }
  }

  .event-header > * {
    margin-left: 1em;
  }

  .date {
    margin-left: 1em;
    @media #{$medium-up} {
      margin-left: 0;
    }
  }
}

.single-event {
  @include grid-column(12, $collapse: false);
}
.map-container {
  @include grid-column(12);
}
.siblings {
  @include grid-column(12, $collapse: false);

  .section-title {
    @include grid-column(12);
  }

  .event {
    @include grid-column(12, $collapse: true);
  }
}
