@mixin uppercase-title {
  text-transform: uppercase;
  font-size: $small-font-size;
  letter-spacing: 0.04em;
}

@mixin footnote {
  font-size: 0.8rem;
}

@mixin underline($color: $u-color, $offset: $u-offset, $weight: $u-weight) {
  background-image: linear-gradient(
    to top,
    transparent,
    transparent $offset,
    $color $offset,
    $color ($offset + $weight),
    transparent ($offset + $weight)
  );
}
