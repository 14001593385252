#page-header {
  @include grid-row();
  margin-bottom: baseline();
}

.logo {
  @include grid-column(12);
  @media #{$medium-up} {
    margin-top: baseline();
  }

  .ir {
    background: url(/logo.svg) no-repeat bottom left;
    background-size: contain;

    display: block;
    width: 220px;
    height: baseline(2);
    text-indent: -999px;
    box-shadow: none;
    margin-left: -10px;
    // margin-bottom: baseline();
  }
}

.nav {
  @include grid-column(12);
  @media #{$medium-up} {
    text-align: right;
    padding-top: baseline(2.2);
  }
  li {
    display: inline-block;
    list-style: none;
    margin-right: $indent;
    @media #{$medium-up} {
      margin: 0 0 0 $indent;
    }
  }
}
