.page {
  @include grid-row();

  .content {
    @include grid-column(12);

    a[href^='#'] {
      background-image: none !important;
    }

    a:not([href*="https://critisticuffs"])
    {
      @include underline(#ccc);
    }

    strong {
      font-weight: normal;
      @include uppercase-title;
    }

    li {
      list-style: none;
    }
  }
}
