$maxWidth: 600px;

@mixin grid-row {
  max-width: $maxWidth;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

@mixin grid-column($n: 12, $collapse: false, $offset: 0) {
  width: $n * (1/12 * 100%);
  margin-left: $offset * (1/12 * 100%);
  @if $collapse == true {
    padding-left: 0;
    padding-right: 0;
  } @else {
    padding-left: baseline();
    padding-right: baseline();
  }
}

//breakpoints
$medium-up: '(min-width: #{$maxWidth})';
