.list {
  @include grid-row();

  .section-title {
    @include grid-column(12);
    text-align: center;
    margin-bottom: baseline();
  }
}

.post-list {
  list-style: none;
  margin-left: 0;

  li {
    margin-bottom: baseline(0.5);
  }
}
